import type { TaskFieldsFragment, TaskInput, TasksQuery } from "~/types/api";
import _ from "lodash";
import Alert from "~/components/alert";
import { IconLoading, IconCheckedSquare } from "~/components/icons";
import Cell from "~/components/table-cell";
import Row from "~/components/table-row";
import TaskRow from "~/components/tasks/row";
import { useFetcherData } from "~/utils/remix";

type Props = {
  id?: string;
  selectedTasks: TaskInput[];
  projectId?: string;
  featureId?: string;
  onLink: (task: TaskFieldsFragment) => void;
};

export default function LinkTasks({
  onLink,
  id,
  featureId,
  projectId,
  selectedTasks
}: Props) {
  const variables = featureId
    ? { parentType: "feature", parentId: featureId! }
    : { parentType: "project", parentId: projectId! };
  const fetcher = useFetcherData<TasksQuery>(
    variables.parentId ? "/resources/tasks/list" : undefined,
    variables
  );
  const tasks = fetcher.data?.tasks || [];

  const filtered = _.sortBy(
    (id
      ? tasks.filter((t) => !t.kanbanCard || t.kanbanCard?.id === id)
      : tasks.filter((t) => !t.kanbanCard)
    ).filter((t) => !t.completed),
    "itemDate",
    "id"
  );

  return (
    <div>
      {!fetcher.data && variables.parentId ? (
        <div className="mb-4 text-center">
          <IconLoading />
        </div>
      ) : filtered.length ? (
        <>
          <Row header>
            <Cell size="40" className="text-center">
              #
            </Cell>
            <Cell>Description</Cell>
            <Cell size="140" className="text-center">
              Who
            </Cell>
            <Cell size="100" className="text-center">
              Due
            </Cell>
            <Cell size="80" className="text-center">
              Priority
            </Cell>
            <Cell size="80" className="text-center">
              Hours
            </Cell>
            <Cell size="30" className="text-center">
              <IconCheckedSquare />
            </Cell>
          </Row>
          {filtered.map((t, index) => (
            <TaskRow
              rowNumber={index + 1}
              task={t}
              key={t.id}
              onSelect={onLink}
              selected={selectedTasks.some((tt) => tt.id === t.id)}
            />
          ))}
        </>
      ) : (
        <Alert mode="warning" style={{ margin: "0px 15px 9px" }}>
          There are no existing actions!
        </Alert>
      )}
    </div>
  );
}
